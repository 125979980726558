import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-aboutuspage',
  templateUrl: './aboutuspage.component.html',
  styleUrls: ['./aboutuspage.component.css']
})
export class AboutuspageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
