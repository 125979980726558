import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-why-stv',
  templateUrl: './why-stv.component.html',
  styleUrls: ['./why-stv.component.css']
})
export class WhyStvComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
