import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { AngularFontAwesomeModule } from "angular-font-awesome";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HeaderComponent } from "./components/header/header.component";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { FeaturesComponent } from "./components/homepagecomponents/features/features.component";
import { WhyChooseUsComponent } from "./components/homepagecomponents/why-choose-us/why-choose-us.component";
import { RecentProjectsComponent } from "./components/homepagecomponents/recent-projects/recent-projects.component";
import { CustomersSayingComponent } from "./components/homepagecomponents/customers-saying/customers-saying.component";
import { CompanyNewsComponent } from "./components/homepagecomponents/company-news/company-news.component";
import { FooterComponent } from "./components/footer/footer.component";
import { AboutuspageComponent } from "./pages/aboutuspage/aboutuspage.component";
import { ProductspageComponent } from "./pages/productspage/productspage.component";
import { ServicespageComponent } from "./pages/servicespage/servicespage.component";
import { ContactuspageComponent } from "./pages/contactuspage/contactuspage.component";
import { AboutcompanyComponent } from "./components/aboutuscomponents/aboutcompany/aboutcompany.component";
import { ServicesComponent } from "./components/aboutuscomponents/services/services.component";
import { OurServicesComponent } from "./components/servicepagecomponent/our-services/our-services.component";
import { GetInTouchComponent } from "./components/servicepagecomponent/get-in-touch/get-in-touch.component";
import { OurLocationsComponent } from "./components/contactuscomponents/our-locations/our-locations.component";
import { WhyStvComponent } from "./components/homepagecomponents/why-stv/why-stv.component";
import { OurMissionVisionComponent } from "./components/aboutuscomponents/our-mission-vision/our-mission-vision.component";
import { ProductsBannerComponent } from "./components/productpagecomponents/products-banner/products-banner.component";
import { ServicesBannerComponent } from "./components/servicepagecomponent/services-banner/services-banner.component";
import { ContactBannerComponent } from "./components/contactuscomponents/contact-banner/contact-banner.component";
import { AboutusBannerComponent } from "./components/aboutuscomponents/aboutus-banner/aboutus-banner.component";
import { OurProductsComponent } from "./components/productpagecomponents/our-products/our-products.component";
import { CarouselComponent } from "./components/homepagecomponents/carousel/carousel.component";

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    HomepageComponent,
    FeaturesComponent,
    WhyChooseUsComponent,
    RecentProjectsComponent,
    CustomersSayingComponent,
    CompanyNewsComponent,
    FooterComponent,
    AboutuspageComponent,
    ProductspageComponent,
    ServicespageComponent,
    ContactuspageComponent,
    AboutcompanyComponent,
    ServicesComponent,
    OurServicesComponent,
    GetInTouchComponent,
    OurLocationsComponent,
    WhyStvComponent,
    OurMissionVisionComponent,
    ProductsBannerComponent,
    ServicesBannerComponent,
    ContactBannerComponent,
    AboutusBannerComponent,
    OurProductsComponent,
    CarouselComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AngularFontAwesomeModule,
    NgbModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
