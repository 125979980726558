import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-our-products",
  templateUrl: "./our-products.component.html",
  styleUrls: ["./our-products.component.css"],
})
export class OurProductsComponent implements OnInit {
  allProducts: boolean = false;
  circularSaw: boolean = false;
  tapsAndSolid: boolean = false;
  hydraulicCutting: boolean = false;
  cuttersHolders: boolean = false;
  passionateCutting: boolean = false;
  sawMitter: boolean = false;
  productsListName = "All Products";

  constructor() {
    this.allProducts = true;
  }

  ngOnInit() {}

  changeCurrentPage(value) {
    this.productsListName = value;
  }
  showAllProducts() {
    this.allProducts = true;
    this.circularSaw = false;
    this.tapsAndSolid = false;
    this.hydraulicCutting = false;
    this.cuttersHolders = false;
    this.passionateCutting = false;
    this.sawMitter = false;
  }

  showCircularSaw() {
    this.allProducts = false;
    this.circularSaw = true;
    this.tapsAndSolid = false;
    this.hydraulicCutting = false;
    this.cuttersHolders = false;
    this.passionateCutting = false;
    this.sawMitter = false;
  }

  showTapsAndSolid() {
    this.allProducts = false;
    this.circularSaw = false;
    this.tapsAndSolid = true;
    this.hydraulicCutting = false;
    this.cuttersHolders = false;
    this.passionateCutting = false;
    this.sawMitter = false;
  }

  showHydraulicCutting() {
    this.allProducts = false;
    this.circularSaw = false;
    this.tapsAndSolid = false;
    this.hydraulicCutting = true;
    this.cuttersHolders = false;
    this.passionateCutting = false;
    this.sawMitter = false;
  }

  showCuttersHolders() {
    this.allProducts = false;
    this.circularSaw = false;
    this.tapsAndSolid = false;
    this.hydraulicCutting = false;
    this.cuttersHolders = true;
    this.passionateCutting = false;
    this.sawMitter = false;
  }

  showPassionateCutting() {
    this.allProducts = false;
    this.circularSaw = false;
    this.tapsAndSolid = false;
    this.hydraulicCutting = false;
    this.cuttersHolders = false;
    this.passionateCutting = true;
    this.sawMitter = false;
  }

  showSawMitter() {
    this.allProducts = false;
    this.circularSaw = false;
    this.tapsAndSolid = false;
    this.hydraulicCutting = false;
    this.cuttersHolders = false;
    this.passionateCutting = false;
    this.sawMitter = true;
  }
}
