import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomepageComponent } from "./pages/homepage/homepage.component";
import { AboutuspageComponent } from "./pages/aboutuspage/aboutuspage.component";
import { ProductspageComponent } from "./pages/productspage/productspage.component";
import { ServicespageComponent } from "./pages/servicespage/servicespage.component";
import { ContactuspageComponent } from "./pages/contactuspage/contactuspage.component";

const routes: Routes = [
  { path: "", component: HomepageComponent },
  { path: "home", component: HomepageComponent },
  { path: "aboutus", component: AboutuspageComponent },
  { path: "products", component: ProductspageComponent },
  { path: "services", component: ServicespageComponent },
  { path: "contactus", component: ContactuspageComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: "enabled" }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
