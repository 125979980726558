import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-carousel",
  templateUrl: "./carousel.component.html",
  styleUrls: ["./carousel.component.css"],
})
export class CarouselComponent implements OnInit {
  constructor() {}
  show: boolean = false;

  images = [1, 2, 3].map(
    () => `https://picsum.photos/900/500?random&t=${Math.random()}`
  );
  ngOnInit() {
    setInterval(() => {
      this.show === false ? (this.show = true) : (this.show = false);
    }, 2000);
  }
}
