import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-our-mission-vision',
  templateUrl: './our-mission-vision.component.html',
  styleUrls: ['./our-mission-vision.component.css']
})
export class OurMissionVisionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
