import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  employeeDetails = [
    // {
    //   name: "Rahul Dhad",
    //   post: "Director Sales",
    //   mailId: "rahuld@shivajitechnoventures.com",
    //   contact: "9922926231",
    // },
    // {
    //   name: "Monica Dhad",
    //   post: "Director Admin",
    //   mailId: "monicad@shivajitechnoventures.com",
    //   contact: "9922926232",
    // },
    // {
    //   name: "Suresh Mayekar",
    //   post: "Manager Sales",
    //   mailId: "sureshm@shivajitechnoventures.com",
    //   contact: "9922926233",
    // },
    // {
    //   name: "Rashmi Pardeshi",
    //   post: "Purchase Head",
    //   mailId: "sales@shivajitechnoventures.com",
    //   contact: "9922926236",
    // },
    // {
    //   name: "Meenal Ranade",
    //   post: "Head Accounts",
    //   mailId: "accounts@shivajitechnoventures.com",
    //   contact: "9922923619",
    // },
    // {
    //   name: "Atul Lagad",
    //   post: "Senior Engineer Sales & Application",
    //   mailId: "sanaswadi@shivajitechnoventures.com",
    //   contact: "9922926234",
    // },
    // {
    //   name: "Rohit Deshmukh",
    //   post: "Engineer Sales & Application",
    //   mailId: "pune@shivajitechnoventures.com",
    //   contact: "9922926255",
    // },
    // {
    //   name: "Shreeram Raut",
    //   post: "Engineer Sales & Application",
    //   mailId: "shriraut123@gmail.com",
    //   contact: "9325315858",
    // },
    // {
    //   name: "Rakesh Gogawale",
    //   post: "Executive Sourcing - CPC Division",
    //   mailId: "rakeshgogawale@rediffmail.com",
    //   contact: "9172898985",
    // },
    // {
    //   name: "Kirtideep Dhandarphale",
    //   post: "Executives Sales",
    //   mailId: "purchases@shivajitechnoventures.com",
    //   contact: "9922926235",
    // },
  ];
  constructor() {}

  ngOnInit() {}
}
