import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  home: boolean = false;
  aboutUs: boolean = false;
  ourProducts: boolean = false;
  services: boolean = false;
  contactUs: boolean = false;
  currentPage = "Home";
  ngOnInit() {
    this.home = true;
  }

  ishome() {
    this.home = true;
    this.aboutUs = false;
    this.ourProducts = false;
    this.services = false;
    this.contactUs = false;
  }

  isAboutUs() {
    this.home = false;
    this.aboutUs = true;
    this.ourProducts = false;
    this.services = false;
    this.contactUs = false;
  }

  isProducts() {
    this.home = false;
    this.aboutUs = false;
    this.ourProducts = true;
    this.services = false;
    this.contactUs = false;
  }

  isServices() {
    this.home = false;
    this.aboutUs = false;
    this.ourProducts = false;
    this.services = true;
    this.contactUs = false;
  }

  isContactUs() {
    this.home = false;
    this.aboutUs = false;
    this.ourProducts = false;
    this.services = false;
    this.contactUs = true;
  }

  changeCurrentPage(value) {
    this.currentPage = value;
    console.log(value);
  }
}
