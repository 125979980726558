import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-contactuspage',
  templateUrl: './contactuspage.component.html',
  styleUrls: ['./contactuspage.component.css']
})
export class ContactuspageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
